import { useState } from "react";
import { 
  Box,
  IconButton,
  InputBase,
  Typography,
  Select,
  MenuItem,
  FormControl,
  useTheme,
  useMediaQuery
} from "@mui/material";
import {
  Search,
  DarkModeOutlined,
  LightModeOutlined,
  Notifications,
  Help,
  Menu,
  Close,
  Send
} from "@mui/icons-material"
import { useDispatch, useSelector } from "react-redux";
import { setMode, setLogout } from "state";
import { useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween";

const Navbar = () => {
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;
  const dark = theme.palette.neutral.dark;
  const background = theme.palette.background.default;
  const primaryLight = theme.palette.primary.light;
  const alt = theme.palette.background.alt;

  const fullName = `${user.firstName} ${user.lastName}`;
  // const fullName = `Ashutosh Tewari`;    //for testing purposes

    return (
      <FlexBetween padding="1rem 6%" backgroundColor={alt}>   {/*pretty nice trick/feature. You are able to pass these things like padding etc. directly as props as this is a mui box not a simple div.*/}
        <FlexBetween gap="1.75rem">
          <Typography
            fontWeight="bold"
            fontSize="clamp(1rem, 2rem, 2.25rem)"
            color="primary"
            onClick={() => navigate("/home")}
            sx={{
              "&:hover": {
                color:primaryLight,
                cursor:"pointer",
                transition:"0.1s"
              }
            }}
            >
              Trendr
          </Typography>
          {isNonMobileScreens && (
            <FlexBetween backgroundColor={neutralLight} borderRadius="20px" gap="3rem" padding="0.1rem 1.5rem">
              <InputBase placeholder="Search..." />
              <IconButton>
                <Search />
              </IconButton>
            </FlexBetween>
          )}
        </FlexBetween>

        {/* Desktop Nav */}

        {isNonMobileScreens ? (
          <FlexBetween gap="2rem">
            <IconButton onClick={() => dispatch(setMode())}>
              {theme.palette.mode === "dark" ? (
                <DarkModeOutlined sx={{fontSize: "25px"}} />
              ) : (
                <LightModeOutlined sx={{color: dark, fontSize: "25px"}} />
              )}
            </IconButton>
            <Send sx={{fontSize: "25px"}} />
            <Notifications  sx={{fontSize: "25px"}} />
            <Help  sx={{fontSize: "25px"}} />
            <FormControl variant="standard" value={fullName}>
              <Select 
                value={fullName}
                sx={{
                  backgroundColor: neutralLight,
                  width: "150px",
                  borderRadius: "0.25rem",
                  p: "0.25rem 1rem",
                  "& .MuiSvgIcon-root": {      //CHECK IT OUT IN INSPECT
                    pr: "0.25rem",
                    width: "3rem"
                  },
                  "& .MuiSelect-select:focus": {
                    backgroundColor: neutralLight
                  }
                }}
                input={<InputBase />}
              >
                <MenuItem value={fullName}>
                  <Typography>{fullName}</Typography>
                </MenuItem>
                <MenuItem onClick={() => dispatch(setLogout())}>
                  Log Out
                </MenuItem>
              </Select>
            </FormControl>
          </FlexBetween>
        
        ) : (
          <IconButton
            onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
          >
            <Menu />
          </IconButton>)}

          {/* Mobile Nav */}
          {!isNonMobileScreens && isMobileMenuToggled && (
            <Box
              position="fixed"
              right="0"
              bottom="0"
              height="100%"
              zIndex="10"
              maxWidth="500px"
              minWidth="300px"
              backgroundColor={background}
            >
              {/* CLOSE ICON */}
              <Box display="flex" justifyContent="flex-end" p="1rem">
                <IconButton
                  onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
                >
                  <Close />
                </IconButton>
              </Box>
            
              {/* MENU ITEMS */}
              <FlexBetween display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="3rem">
                <IconButton
                 onClick={() => dispatch(setMode())}
                 sx={{fontSize: "25px"}}
                >
                  {theme.palette.mode === "dark" ? (
                    <DarkModeOutlined sx={{fontSize: "25px"}} />
                  ) : (
                    <LightModeOutlined sx={{color: dark, fontSize: "25px"}} />
                  )}
                </IconButton>
                <Send sx={{fontSize: "25px"}} />
                <Notifications  sx={{fontSize: "25px"}} />
                <Help  sx={{fontSize: "25px"}} />
                <FormControl variant="standard" value={fullName}>
                  <Select 
                    value={fullName}
                    sx={{
                      backgroundColor: neutralLight,
                      width: "150px",
                      borderRadius: "0.25rem",
                      p: "0.25rem 1rem",
                      "& .MuiSvgIcon-root": {      //CHECK IT OUT IN INSPECT
                        pr: "0.25rem",
                        width: "3rem"
                      },
                      "& .MuiSelect-select:focus": {
                        backgroundColor: neutralLight
                      }
                    }}
                    input={<InputBase />}
                  >
                    <MenuItem value={fullName}>
                      <Typography>{fullName}</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => dispatch(setLogout())}>
                      Log Out
                    </MenuItem>
                  </Select>
                </FormControl>
              </FlexBetween>
            </Box>
          )}
      </FlexBetween>
    )
  }
  
  export default Navbar;

